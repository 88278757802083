import { navigate } from 'gatsby-link';
import React, { useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  certSlugTypeMap,
  superBlockCertTypeMap,
  SuperBlocks
} from '../../../../../config/certification-settings';
import { createFlashMessage } from '../../../components/Flash/redux';
import { FlashMessages } from '../../../components/Flash/redux/flash-messages';
import {
  isSignedInSelector,
  userFetchStateSelector,
  currentCertsSelector
} from '../../../redux/selectors';
import { User, Steps } from '../../../redux/prop-types';
import { verifyCert } from '../../../redux/settings/actions';
import { certMap } from '../../../resources/cert-and-project-map';
import TrophyChallengeFilled from '../../../assets/icons/trophy-challenge-filled';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';

interface CertChallengeProps {
  // TODO: create enum/reuse SuperBlocks enum somehow
  createFlashMessage: typeof createFlashMessage;
  fetchState: {
    pending: boolean;
    complete: boolean;
    errored: boolean;
    error: null | string;
  };
  currentCerts: Steps['currentCerts'];
  superBlock: SuperBlocks;
  title: (typeof certMap)[number]['title'];
  user: User;
  verifyCert: typeof verifyCert;
  isCompleted: boolean;
}

const honestyInfoMessage = {
  type: 'info',
  message: FlashMessages.HonestFirst,
  variables: {
    dismissible: true
  }
};

const mapStateToProps = (state: unknown) => {
  return createSelector(
    currentCertsSelector,
    userFetchStateSelector,
    isSignedInSelector,
    (
      currentCerts,
      fetchState: CertChallengeProps['fetchState'],
      isSignedIn
    ) => ({
      currentCerts,
      fetchState,
      isSignedIn
    })
  )(state as Record<string, unknown>);
};

const mapDispatchToProps = {
  createFlashMessage,
  verifyCert
};

const CertChallenge = ({
  createFlashMessage,
  currentCerts,
  superBlock,
  verifyCert,
  title,
  fetchState,
  isCompleted,
  user: { isHonest, username }
}: CertChallengeProps): JSX.Element => {
  const { t } = useTranslation();
  const [isCertified, setIsCertified] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);

  // @ts-expect-error Typescript is confused
  const certSlug = certMap.find(x => x.title === title).certSlug;

  useEffect(() => {
    const { pending, complete } = fetchState;

    if (complete && !pending) {
      setUserLoaded(true);
    }
  }, [fetchState]);

  const certSlugTypeMapTyped: { [key: string]: string } = certSlugTypeMap;
  const superBlockCertTypeMapTyped: { [key: string]: string } =
    superBlockCertTypeMap;

  useEffect(() => {
    setIsCertified(
      currentCerts?.find(
        (cert: { certSlug: string }) =>
          certSlugTypeMapTyped[cert.certSlug] ===
          superBlockCertTypeMapTyped[superBlock]
      )?.show ?? false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCerts]);

  const certLocation = `/certification/${username}/${certSlug}`;

  const handleClick = () => {
    if (isHonest) {
      verifyCert(certSlug);
      void navigate(
        isCertified ? certLocation : `/certificate-of-participation`
      );
    } else {
      createFlashMessage(honestyInfoMessage);
    }
  };

  return (
    <div className='block'>
      {isCompleted && (
        <Button
          buttonType={ButtonTypes.Primary}
          style={{ width: 'max-content', margin: '0 auto' }}
          onClick={handleClick}
        >
          <TrophyChallengeFilled
            className='trophy-challenge'
            width={24}
            height={24}
          />
          <p className='cert-text'>
            {isCertified && userLoaded
              ? t('buttons.show-cert')
              : t('buttons.go-to-settings')}
          </p>
        </Button>
      )}
    </div>
  );
};

CertChallenge.displayName = 'CertChallenge';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CertChallenge));
